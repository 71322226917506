import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CSSTransition from "react-transition-group/CSSTransition"
// import { useSwipeable } from "react-swipeable"
import { Helmet } from "react-helmet"

import SocialShareImage from "../../../components/beheard components/images/full-potential-share-pt.jpg"

import Swipe from "react-easy-swipe"

/** Base Components **/
import Layout from "../../../components/beheard components/components/layout"

/** Page Components **/
import Header from "../../../components/beheard components/components/header-pt"
import Footer from "../../../components/beheard components/components/footer/footer-pt"
import Landing from "../../../components/beheard components/components/landing/landing-pt"

import TheCommunityVideo from "../../../components/beheard components/components/the-community/the-community-video-pt"
import ThankyouStickers from "../../../components/beheard components/components/thankyou/thankyou-stickers"
import RecordYourVoiceContent from "../../../components/beheard components/components/record-your-voice/record-your-voice-pt"
import TheCommunityLeftContent from "../../../components/beheard components/components/the-community/the-community-left-pt"
import TheCommunityRightContent from "../../../components/beheard components/components/the-community/the-community-right-pt"
import FullPotentialContent from "../../../components/beheard components/components/full-potential/full-potential-pt"
import Commitments from "../../../components/beheard components/components/commitments/commitments-pt"
import HearOtherWomenVoicesLeft from "../../../components/beheard components/components/hear-other-women-voices/hear-other-women-voices-left-pt"
import HearOtherWomenVoicesRight from "../../../components/beheard components/components/hear-other-women-voices/hear-other-women-voices-right-pt"
import Thankyou from "../../../components/beheard components/components/thankyou/thankyou-pt"
import ThankyouSocialmedia from "../../../components/beheard components/components/thankyou/thankyou-socialmedia-pt"

const IndexPage = props => {
  const imageData = useStaticQuery(graphql`
    query {
      stickerClimateThreshold: file(
        relativePath: { eq: "stickers/sticker-climate-threshold.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerHereToBeHeard: file(
        relativePath: { eq: "stickers/sticker-here-to-be-heard.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerCommunities: file(
        relativePath: { eq: "stickers/sticker-communities.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const pageTitle =
    "O que precisa mudar para que mais mulheres possam alcançar seu máximo potencial? | Mars"
  const pageMetaDescription =
    "Mulheres, vocês podem ajudar a mudar o amanhã. O que precisa mudar para que mais mulheres possam alcançar seu potencial máximo? Junte sua voz ao nosso estudo global e ajude a moldar o futuro das mulheres em todo o mundo."

  // Stage
  // const ogimage = "http://stage.beheard.mars.com" + SocialShareImage

  // Production
  const ogimage = "http://beheard.mars.com" + SocialShareImage

  const [section, setSection] = useState("landing")
  const [navOpen, setNavOpen] = useState("")
  // const [headerBg, setHeaderBg] = useState("")
  const [communityVideo, setCommunityVideo] = useState("")
  const [commitments, setCommitments] = useState("")

  const [scrollControl, setScrollControl] = useState(true)

  useEffect(() => {
    if (window.location.href.indexOf("thankyou") > -1) {
      setSection("thankyou")
    } else {
      setTimeout(() => {
        if (section === "landing") {
          setSection("record-your-voice")
        }
      }, 1000)
    }
    if (typeof window !== `undefined`) {
      const itm =
        typeof window !== `undefined`
          ? window.localStorage.getItem("refreshCounter")
          : false
      return itm
        ? window.localStorage.removeItem("refreshCounter")
        : (window.localStorage.setItem("refreshCounter", 1),
          window.location.reload(false))
    }
  }, [])

  // landing
  // record-your-voice
  // the-community
  // -- the-community video
  // full-potential
  // commitments commitments-stickers
  // -- commitments sticker-more-education-for-women
  // -- commitments section-answer-help
  // -- commitments section-no-negative-stereotypes
  // -- commitments section-more-women-in-power
  // -- commitments section-equal-pay
  // -- commitments section-financial-freedom
  // -- commitments section-female-changelles
  // hear-other-women-voices
  // thankyou
  // thankyou social-media

  let changeSectionDownHandler
  useEffect(() => {
    changeSectionDownHandler = () => {
      if (section === "landing") {
        setSection("record-your-voice")
        setScrollControl(false)
      } else if (section === "record-your-voice") {
        setSection("the-community")
        setScrollControl(false)
      } else if (section === "the-community") {
        setSection("the-community video")
        setScrollControl(false)
      } else if (section === "the-community video") {
        setSection("full-potential")
        setScrollControl(false)
        setCommunityVideo("slide-up")
      } else if (section === "full-potential") {
        setSection("commitments commitments-stickers")
        setScrollControl(false)
      } else if (
        section === "commitments commitments-stickers" ||
        section ===
          "commitments commitments-stickers sticker-more-education-for-women-reverse" ||
        section ===
          "commitments commitments-stickers section-answer-help-reverse" ||
        section ===
          "commitments commitments-stickers section-no-negative-stereotypes-reverse" ||
        section ===
          "commitments commitments-stickers section-more-women-in-power-reverse" ||
        section ===
          "commitments commitments-stickers section-equal-pay-reverse" ||
        section ===
          "commitments commitments-stickers section-financial-freedom-reverse" ||
        section ===
          "commitments commitments-stickers section-female-changelles-reverse"
      ) {
        setSection("hear-other-women-voices")
        setScrollControl(false)
        setCommitments("slide-up")
      } else if (section === "hear-other-women-voices") {
        setSection("hear-other-women-voices enable-scroll")
        //setScrollControl(false)
      }
      // else if (section === "thankyou social-media enable-scroll") {
      //   if (window.pageYOffset > 10) {
      //     setHeaderBg("header-bg")

      //   }
      // }
    }
  })

  let changeSectionUpHandler
  useEffect(() => {
    changeSectionUpHandler = () => {
      if (section === "hear-other-women-voices enable-scroll") {
        if (window.pageYOffset === 0) {
          setSection("hear-other-women-voices")
          //setScrollControl(false)
        }
      } else if (section === "hear-other-women-voices") {
        setSection("commitments commitments-stickers")
        setScrollControl(false)
        //setCommitments("")
      } else if (
        section === "commitments commitments-stickers" ||
        section ===
          "commitments commitments-stickers sticker-more-education-for-women-reverse" ||
        section ===
          "commitments commitments-stickers section-answer-help-reverse" ||
        section ===
          "commitments commitments-stickers section-no-negative-stereotypes-reverse" ||
        section ===
          "commitments commitments-stickers section-more-women-in-power-reverse" ||
        section ===
          "commitments commitments-stickers section-equal-pay-reverse" ||
        section ===
          "commitments commitments-stickers section-financial-freedom-reverse" ||
        section ===
          "commitments commitments-stickers section-female-changelles-reverse"
      ) {
        setSection("full-potential")
        setScrollControl(false)
        setCommitments("")
      } else if (section === "full-potential") {
        setSection("the-community video")
        setScrollControl(false)
      } else if (section === "the-community video") {
        setSection("the-community")
        setScrollControl(false)
        setCommunityVideo("")
      } else if (section === "the-community") {
        setSection("record-your-voice")
        setScrollControl(false)
      } else if (section === "record-your-voice") {
        setSection("landing")
      }
      // else if (section === "thankyou social-media enable-scroll") {
      //   if (window.pageYOffset < 10) {
      //     setHeaderBg("")
      //   }
      // }
    }
  })

  //console.log(`${props.location.origin}/en `)

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="../../BeHeard/css/layout.css" />
        <link rel="stylesheet" href="../../BeHeard/css/layout.media.css" />
        <link rel="stylesheet" href="../../BeHeard/css/layout.media.mx.css" />
        <link rel="stylesheet" href="../../BeHeard/css/layout.media.fr.css" />
        <link rel="stylesheet" href="../../BeHeard/css/layout.media.pt.css" />
      </Helmet>
      <Layout>
        <div
          className={`${section} ${navOpen}`}
          id="main"
          onWheel={event => {
            if (event.nativeEvent.wheelDelta > 0) {
              //console.log("scroll up")
              if (scrollControl) {
                changeSectionUpHandler()
              }
            } else {
              //console.log("scroll down")
              if (scrollControl) {
                changeSectionDownHandler()
              }
            }
          }}
        >
          <Header
            setSection={setSection}
            setNavOpen={setNavOpen}
            setCommunityVideo={setCommunityVideo}
            setCommitments={setCommitments}
            // headerBg={headerBg}
          />
          <main className="bg-green section-hldr pt" id="section-hldr">
            <Swipe
              onSwipeUp={() => {
                if (scrollControl) {
                  changeSectionDownHandler()
                }
              }}
              onSwipeDown={() => {
                if (scrollControl) {
                  changeSectionUpHandler()
                }
              }}
              tolerance={60}
              className="w-100 h-100"
            >
              <div className="section-hldr-inner">
                {/* <div className="section-hldr-inner" {...handlers}> */}
                <div className="container">
                  <div className="row no-gutters align-md-items-center">
                    <div className="col-lg-6 h-100 position-relative">
                      {/* Record Your Voice */}

                      <CSSTransition
                        in={section === "record-your-voice"}
                        timeout={{ enter: 200, exit: 4000 }}
                        unmountOnExit
                      >
                        <RecordYourVoiceContent
                          setScrollControl={setScrollControl}
                        />
                      </CSSTransition>

                      {/* The Community Content - Left */}
                      <CSSTransition
                        in={section === "the-community"}
                        timeout={{ enter: 200, exit: 4000 }}
                        unmountOnExit
                      >
                        <TheCommunityLeftContent
                          setScrollControl={setScrollControl}
                        />
                      </CSSTransition>

                      {/* Full Potential Platform */}
                      <CSSTransition
                        in={section === "full-potential"}
                        timeout={{ enter: 200, exit: 4000 }}
                        unmountOnExit
                      >
                        <FullPotentialContent
                          setScrollControl={setScrollControl}
                        />
                      </CSSTransition>

                      {/* Hear Other Women's Voices - Left */}
                      <CSSTransition
                        in={
                          section === "hear-other-women-voices" ||
                          section === "hear-other-women-voices enable-scroll"
                        }
                        timeout={{ enter: 200, exit: 4000 }}
                        unmountOnExit
                      >
                        <HearOtherWomenVoicesLeft
                          setSection={setSection}
                          setScrollControl={setScrollControl}
                          origin={props.location.origin}
                        />
                      </CSSTransition>

                      {/* Thankyou */}
                      <CSSTransition
                        in={section === "thankyou"}
                        timeout={{ enter: 200, exit: 2000 }}
                        unmountOnExit
                      >
                        <Thankyou setSection={setSection} />
                      </CSSTransition>

                      {/* Thankyou - Socialmedia */}
                      <CSSTransition
                        in={
                          section === "thankyou social-media" ||
                          section === "thankyou social-media enable-scroll"
                        }
                        timeout={{ enter: 200, exit: 2000 }}
                        unmountOnExit
                      >
                        <ThankyouSocialmedia
                          setSection={setSection}
                          origin={props.location.origin}
                        />
                      </CSSTransition>
                    </div>

                    <div className="col-lg-6 h-100 position-relative">
                      {/* The Community Content - Right */}
                      <div className="d-none d-lg-block">
                        <CSSTransition
                          in={section === "the-community"}
                          timeout={{ enter: 200, exit: 4000 }}
                          unmountOnExit
                        >
                          <TheCommunityRightContent />
                        </CSSTransition>
                      </div>
                      {/* Hear Other Women's Voices - Right */}
                      <div className="d-none d-lg-block">
                        <CSSTransition
                          in={
                            section === "hear-other-women-voices" ||
                            section === "hear-other-women-voices enable-scroll"
                          }
                          timeout={{ enter: 200, exit: 4000 }}
                          unmountOnExit
                        >
                          <HearOtherWomenVoicesRight
                            origin={props.location.origin}
                            // setSection={setSection}
                          />
                        </CSSTransition>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Women and Shadow, Text Lottie Animations */}
                <Landing />

                <figure className="mb-0 sticker sticker-climate-threshold">
                  <Img
                    fluid={
                      imageData.stickerClimateThreshold.childImageSharp.fluid
                    }
                    alt=""
                  />
                </figure>
                <figure className="mb-0 sticker sticker-here-to-be-heard">
                  <Img
                    fluid={imageData.stickerHereToBeHeard.childImageSharp.fluid}
                    alt=""
                  />
                </figure>
                <figure className="mb-0 sticker sticker-communities">
                  <Img
                    fluid={imageData.stickerCommunities.childImageSharp.fluid}
                    alt=""
                  />
                </figure>

                {/* Thankyou - Stickers*/}

                <ThankyouStickers />

                {/* The Community - Video */}
                <CSSTransition
                  in={section === "the-community video"}
                  timeout={{ enter: 200, exit: 1500 }}
                  unmountOnExit
                >
                  <TheCommunityVideo
                    setCommunityVideo={communityVideo}
                    setScrollControl={setScrollControl}
                    origin={props.location.origin}
                  />
                </CSSTransition>
                {/* <TheCommunityVideo
                  setCommunityVideo={communityVideo}
                  setScrollControl={setScrollControl}
                  origin={props.location.origin}
                /> */}

                {/* Commitments */}
                <CSSTransition
                  in={
                    section === "commitments commitments-stickers" ||
                    section ===
                      "commitments sticker-more-education-for-women" ||
                    section === "commitments section-answer-help" ||
                    section === "commitments section-no-negative-stereotypes" ||
                    section === "commitments section-more-women-in-power" ||
                    section === "commitments section-equal-pay" ||
                    section === "commitments section-financial-freedom" ||
                    section === "commitments section-female-changelles" ||
                    section ===
                      "commitments commitments-stickers sticker-more-education-for-women-reverse" ||
                    section ===
                      "commitments commitments-stickers section-answer-help-reverse" ||
                    section ===
                      "commitments commitments-stickers section-no-negative-stereotypes-reverse" ||
                    section ===
                      "commitments commitments-stickers section-more-women-in-power-reverse" ||
                    section ===
                      "commitments commitments-stickers section-equal-pay-reverse" ||
                    section ===
                      "commitments commitments-stickers section-financial-freedom-reverse" ||
                    section ===
                      "commitments commitments-stickers section-female-changelles-reverse"
                  }
                  timeout={{ enter: 200, exit: 1500 }}
                  unmountOnExit
                >
                  <Commitments
                    section={section}
                    setSection={setSection}
                    setScrollControl={setScrollControl}
                    setCommitments={commitments}
                  />
                </CSSTransition>

                {/* {section === "commitments commitments-stickers" ||
            section === "commitments sticker-more-education-for-women" ||
            section === "commitments section-equal-pay" ? (
              <Commitments setSection={setSection} />
            ) : null} */}
              </div>
            </Swipe>
          </main>
        </div>
        <CSSTransition
          in={
            section === "hear-other-women-voices enable-scroll" ||
            section === "thankyou social-media enable-scroll"
          }
          timeout={{ enter: 200 }}
          unmountOnExit
        >
          <Footer />
        </CSSTransition>
        {/* <ScriptTag
          isHydrating={true}
          type="text/javascript"
          src="https://stage-footer.mars.com/js/footer.js.aspx?stage-beheard-mars-com"
        /> */}
        <button className="ot-sdk-show-settings" id="ot-sdk-btn">
          Configurações de cookies
        </button>
      </Layout>
    </>
  )
}

export default IndexPage
